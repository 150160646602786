import React, { useState, useRef } from 'react';
import axios from 'axios';
import './App.css';

function App() {
  const [productName, setProductName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [mrpPrice, setMrpPrice] = useState('');
  const [salePrice, setSalePrice] = useState('');
  const [barcodeImage, setBarcodeImage] = useState('');
  const [loading, setLoading] = useState(false);
  const printRef = useRef(); // Reference for the printable area

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios.post('http://seocompanyinpune.in.net/add-product', {
      name: productName,
      company: companyName,
      mrp_price: mrpPrice,
      sale_price: salePrice,
    }).then((response) => {
      setBarcodeImage(response.data.barcodeImage);
      setLoading(false);
    }).catch((error) => {
      console.error("There was an error!", error);
      setLoading(false);
    });
  };

  // Function to handle print
  const handlePrint = () => {
    const content = printRef.current;
    const printWindow = window.open('', '', 'width=600,height=400');
    printWindow.document.write('<html><head><title>Print Barcode</title>');
    printWindow.document.write('</head><body >');
    printWindow.document.write(content.innerHTML); // Print only the barcode
    printWindow.document.write('</body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className="app-container">
      <h1>Barcode Generator</h1>
      <form onSubmit={handleSubmit} className="form-container">
        <div className="form-group">
          <label>Product Name:</label>
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            placeholder="Enter product name"
            required
          />
        </div>
        <div className="form-group">
          <label>Company Name:</label>
          <input
            type="text"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            placeholder="Enter company name"
            required
          />
        </div>
        <div className="form-group">
          <label>MRP Price (Rs):</label>
          <input
            type="number"
            value={mrpPrice}
            onChange={(e) => setMrpPrice(e.target.value)}
            placeholder="Enter MRP price"
            required
          />
        </div>
        <div className="form-group">
          <label>Sale Price (Rs):</label>
          <input
            type="number"
            value={salePrice}
            onChange={(e) => setSalePrice(e.target.value)}
            placeholder="Enter sale price"
            required
          />
        </div>
        <button type="submit" className="btn-submit" disabled={loading}>
          {loading ? "Generating..." : "Generate Barcode"}
        </button>
      </form>

      {barcodeImage && (
        <div className="barcode-container" ref={printRef}>
          {/* Only barcode image will be printed */}
          <img src={barcodeImage} alt="Generated Barcode" className="barcode-image" />
        </div>
      )}

      {barcodeImage && (
        <button onClick={handlePrint} className="btn-print">
          Print Barcode
        </button>
      )}
    </div>
  );
}

export default App;
